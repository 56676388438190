@import '../../global.scss';

.portfolio {
    background-color: $whiteColor;
    display: flex;
    flex-direction: column;
    align-items: center;


    h1 {
        font-size: 50px;
        color: $blueColor;

        @include mobile {
            font-size: 35px;
            flex: none;
        }
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile {
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
        }

        li {
            font-size: 14px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;

            &.active {
                background-color: $orangeColor;
                color: $whiteColor;
            }
        }
    }

    .arrowDownward {
        display: none;
    }
    
    @include mobile {
        .arrowDownward {
            position: relative;
            display: inherit;
            flex-shrink: 0;
        }
    }

    .container {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        .item {
            width: 40%;
            height: fit-content;
            max-width: 450px;
            border-radius: 20px;
            border: 1px solid lightgrey;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $whiteColor;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;

            @include mobile {
                width: 90%;
                height: 450px;
            }

            h3 {
                font-size: 20px;
                padding-bottom: 0.5em;
            }

            .item__descr {
                position: absolute;
                padding: 0.5em;
                text-align: center;
                a {
                    line-height: 10px;
                    color: $whiteColor;
                }
            }

            img {
                width: 90%;
                margin: 20px;
                height: auto;
                object-fit: cover;
                z-index: 1;
            }

            .img-mobile {
                display: none;
            }

            @include mobile {
                .img-desktop {
                    display: none;
                }
                .img-mobile {
                    height: 90%;
                    width: auto;
                    display: inherit;
                }
    
            }

            &:hover, &:active {
                background-color: $blueColor;
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}