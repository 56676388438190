@import "../../global.scss";

.contact {
  background-color: $whiteColor;
  display: flex;

  .arrowDownward {
    display: none;
  }
  
  @include mobile {
    flex-direction: column;

    .arrowDownward {
      display: inherit;
      align-self: center;
      margin-bottom: 2vh;
      flex-shrink: 0;
      transform: rotate(180deg);
    }  
  }

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }

    @include mobile {
      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex: 2;
    }

    h2 {
      font-size: 30px;
      color: $orangeColor;
      margin-bottom: 1vh;
    }

    form {
      width: 70%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      @include mobile {
        width: 80%;
        height: 80%;
        justify-content: space-between;
      }

      input {
        width: 90%;
        height: 30px;
        font-size: 14px;

        @include mobile {
          height: 8%;
        }
      }

      textarea {
        width: 90%;
        height: 200px;
        font-size: 14px;

        @include mobile {
          height: 70%;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: $whiteColor;
        background-color: $orangeColor;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      span {
        display: none;
        color: $blueColor;
      }
    }
  }
}