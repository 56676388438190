@import "../../global.scss";

.skills {
    background-color: $blueColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-height: 620px;

    @include mobile {
        max-height: none;
    }

    h1 {
        color: $whiteColor;
        position: absolute;
        top: 50px;

        @include mobile {
            top: 20px;
        }
    }

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
        }

        &.left {
            left: 4vw;
            transform: rotateY(180deg);
        }

        &.right {
            right: 4vw;
        }
    }

    .arrowDownward {
        display: none;
    }

    @include mobile {
        .arrowDownward {
            position: absolute;
            display: inherit;
            bottom: 2vh;
            left: calc(50vw - 25px);

            .iconArrowDownward {
                color: $whiteColor;
            }
        }
    }
    
    .slider {
        height: 60%;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile {
            height: 100%;
            flex-direction: column;
            justify-content: center;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
               width: 700px;
               height: 100%;
               background-color: $whiteColor;
               border-radius: 20px;
               display: flex;
               align-items: center;
               justify-content: center;

               @include mobile {
                    width: 80%;
                    height: 170px;
                    margin: 15px 0;
                }

               .left {
                   flex: 4;
                   display: flex;
                   align-items: center;
                   justify-content: center;

                   .leftContainer {
                       width: 90%;
                       height: 70%;
                       display: flex;
                       align-items: center;
                       justify-content: center;

                       .imgContainer {
                            width: 40px;
                            height: 40px;
                            background-color: rgb(235, 178, 158);
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @include mobile {
                                width: 20px;
                                height: 20px;
                            }

                            img {
                                width: 25px;

                                @include mobile {
                                    width: 15px;
                                }
                            }
                        }
                        h2 {
                            color: $orangeColor;
                            font-size: 26px;

                            @include mobile{
                                font-size: 13px;
                            }
                        }
                        p {
                            font-size: 13px;

                            @include mobile{
                                display: none;
                            }
                        }
                        span {
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                   }
               }

               .right{
                    flex: 8;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-auto-rows: 70px;
                    font-size: 40px;
                    text-align: center;

                    p {
                        font-size: 12px;
                        padding: 4px;
                    }
                    
                    @include mobile{
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-auto-rows: 35px;
                        font-size: 20px;

                        p {
                            font-size: 8px;
                            padding: 2px;
                        }
                    }
                }
            }
        }
    }
}
