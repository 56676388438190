@import 'global.scss';

.app {
    height: 100vh;
    width: 100%;
    max-width: $maxWidth;
    margin: auto;

    .sections {
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: mandatory;
        scrollbar-width: none; //for Firefox
        &::-webkit-scrollbar {
            display: none;
        }

        /* for each element inside the section tag */
        > * {
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}