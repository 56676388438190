@import "../../global.scss";

.about {
  background-color: $whiteColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none; //for Firefox
  &::-webkit-scrollbar { display: none; }

  .arrowDownward {
    display: none;
  }

  @include mobile{
    justify-content: space-between;
    height: 100%;
    .arrowDownward {
      position: relative;
      bottom: 2vh;
      display: inherit;
    }
  }

  .arrow {
    display: none;
    height: 40px;
    margin-top: 40vh;
    position: absolute;
    cursor: pointer;

    @include mobile {
        display: inherit;
    }

    &.left {
        left: -1vw;
        transform: rotateY(180deg);
    }

    &.right {
        right: -1vw;
    }
  }

  h1 {
    font-size: 2em;
    margin-top: 1em;
    color: $blueColor;

    @include mobile{
      font-size: 2em;
      margin-top: 0.5em;
      scrollbar-width: none; //for Firefox
        &::-webkit-scrollbar {
            display: none;
        }
    }
  }

  .container {
    width: 100%;
    height: 80%;
    min-height: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
    scrollbar-width: none; //for Firefox
        &::-webkit-scrollbar {
            display: none;
        }

    @include mobile{
      width: max-content;
      position: absolute;
      margin-top: 5vh;
      left: 0;
      transition: all 0.7s ease-out;
      justify-content: start;
    }

    .card {
      width: 28%;
      height: 70%;
      border-radius: 10px;
      background-color: $orangeColor;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      margin: 0 20px;
      transition: all 1s ease;

      @include mobile{
        width: 60vw;
        flex-shrink: 0;
        margin: 0 15vw;
        padding: 5vw;
        scrollbar-width: none; //for Firefox
        &::-webkit-scrollbar {
            display: none;
        }
      }

      &.featured{
        width: 28%;
        height: 70%;
        margin: 0 30px;

        @include mobile{
          width: 70%;
          height: fit-content;
          margin: 10px 0 0 0;
        }
      }

      &:hover{
          transform: scale(1.1);
      }

      .top {
        flex-grow: 1;
        
        .top-content {
          margin: auto;
          background-color: $whiteColor;
          border-radius: 5px;
          width: 60px;
          height: 60px;
          display: grid;

          @include mobile {
            width: 40px;
            height: 40px;
          }

          .user {
            height: 40px;
            width: 40px;
            color: $blueColor;
            border-radius: 50%;
            margin: auto;

            @include mobile {
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      .center {
        flex-grow: 6;
        display: grid;

        .center-content{
          margin: auto;
          padding: 10px;
          border-radius: 10px;
          background-color: $whiteColor;
          white-space: pre-line;
          font-size: 14px;
          color: $blueColor;
          text-align: center;
          scrollbar-width: none; //for Firefox
          &::-webkit-scrollbar {
              display: none;
          }

          @include mobile{
            font-size: 12px;
            padding: 5px;
            height: fit-content;
          }
        }
      }

      .bottom {
        flex-grow: 1;
        display: grid;

        .bottom-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h3 {
            margin-bottom: 5px;
            color: $whiteColor;
            padding-top: 0.5em;

            @include mobile{
              font-size: 14px;
            }
          }
          h4{
            color: $whiteColor;
            text-align: center;
            @include mobile{
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}