$blueColor: #223145;
$whiteColor: antiquewhite;
$orangeColor: #b54921;
$maxWidth: 1280px; // only use px or change unit for slider class

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

body {
    background-color: $whiteColor;
}

:export {
    maxWidth: $maxWidth;
}
