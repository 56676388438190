@import '../../global.scss';

.intro {
    background-color: $whiteColor;
    display: flex;

    .arrowDownward {
        display: none;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
        .arrowDownward {
            display: inherit;
            margin-bottom: 2vh;
        }
    }

    .left {
        flex: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include mobile {
            flex: content;
        }

        .imgContainer {
            width: 80%;
            background-color: $whiteColor;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobile {
                align-items: start;
                height: 250px;
                width: auto;
            }


            img {
                height: 90%;
                border-radius: 50%;

                @include mobile {
                    // height: 50%;

                }
            }
        }
    }

    .right {
        flex: 50%;
        position: relative;

        @include mobile {
            flex: content;
        }
       
        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding: 0;
                height: fit-content;
                align-items: center;
            }

            h1 {
               font-size: 60px;
               margin: 10px 0;
               color: $orangeColor;

               @include mobile {
                   font-size: 40px;
               }
            }

            h2 {
                font-size: 35px;
                color: $blueColor;
            }

            h3 {
                font-size: 30px;
                color: $blueColor;

                @include mobile {
                    font-size: 20px;
                }

                span {
                    font-size: inherit;
                    color: $orangeColor;
                }
            }
        }
    }
}